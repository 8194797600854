<template>
  <!-- Multiple Choice Submission -->
  <div>
    <b-row v-if="isLoading">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center mt-4">
          <b-spinner
            variant="primary"
            class="mr-1"
          />
          <p class="mt-1">
            Memuat Data Pertemuan
          </p>
        </div>
      </b-col>
    </b-row>
    <!-- Multiple Choice Submission Card Header -->
    <b-card
      v-else
      bg-variant="transparent"
      class="material-card border-light-secondary mb-1"
    >
      <div class="d-flex">
        <div class="mr-1">
          <b-avatar
            rounded="lg"
            variant="light-success"
          >
            <i class="card-icon ti ti-list-check" />
          </b-avatar>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <span class="text-muted">Tugas Essay </span>
              <h6 class="font-weight-bolder small-gap-bottom">
                Nilai : {{ taskData.point }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <!-- Multiple Choice -->
    <b-card
      v-for="(question, index) in questions"
      :key="index"
      bg-variant="transparent"
      class="material-card border-light-secondary p-1 mb-1"
    >
      <b-row>
        <b-col md="12">
          <div>
            <h6 class="font-weight-bolder small-gap-bottom">
              Pertanyaan
            </h6>

            <!-- Exam Question -->
            <div class="d-flex mb-1">
              <b-avatar
                class="small-gap-right"
                variant="primary"
              >
                {{ question.number }}
              </b-avatar>
              <p v-html="question.question" class="w-100"></p>
            </div>
          </div>
        </b-col>
        <b-col md="12">
          <div>
            <h6 class="font-weight-bolder small-gap-bottom">
              Jawaban Teks
            </h6>

            <!-- Exam Question -->
            <div class="d-flex mb-1">
              <!-- <p class="font-weight-normal">
                  {{ quiz.question }}
                </p> -->
              <p v-html="question.answer_text" />
            </div>
          </div>
        </b-col>
        <b-col
          v-if="question.answer_file"
          md="12"
        >
          <div>
            <h6 class="font-weight-bolder small-gap-bottom">
              Jawaban File
            </h6>

            <!-- Exam Question -->
            <b-button
              :href="question.answer_file"
              variant="primary"
              target="_blank"
            >
              <i class="ti ti-download" /> Buka
            </b-button>
          </div>
        </b-col>
        <b-col md="12">
          <div>
            <h6 class="font-weight-bolder small-gap-bottom">
              Nilai
            </h6>

            <b-form-input
              v-model.number="question.point"
              type="number"
              :number="true"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="(questions.length !== 0)"
      bg-variant="transparent"
      class="material-card border-light-secondary p-0 mb-1"
    >
      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="ml-1"
          @click="submitForm"
        >
          <b-spinner
            v-if="componentLoading"
            class="mr-1"
            variant="light"
            small
          />
          <i
            v-if="!componentLoading"
            class="align-middle ti ti-device-floppy small-gap-right"
          />
          <span class="align-middle">Simpan</span>
        </b-button>
      </div>
    </b-card>
    <b-card
      v-else
      bg-variant="transparent"
      class="material-card border-light-secondary p-0 mb-1"
    >
      <div class="d-flex justify-content-center">
        <h1>User tidak menjawab tugas</h1>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import { checkAuthorizeRole } from '@/auth/utils'

import {
  BBadge,
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormRadio,
  BInputGroupPrepend,
  BAvatar,
  BMedia,
  BProgress,
  BFormGroup,
  BSpinner,
  BInputGroupAppend,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { reqGetEssayQuestionAnswers, reqSaveEssayQuestionCorrection } from '@/api/admin/task'

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    BInputGroupPrepend,
    BInputGroupAppend,
    BAvatar,
    BMedia,
    BProgress,
    BFormGroup,
    BSpinner,
    BFormInvalidFeedback,
  },
  filters: {
    upperCase(value) {
      return value.toUpperCase()
    },
  },
  data() {
    return {
      // state
      isLoading: true,
      componentLoading: false,

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      taskData: {},
      questions: [],
      errors: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  created() {
    this.loadQuestions()
  },
  methods: {
    loadQuestions() {
      this.isLoading = true
      reqGetEssayQuestionAnswers(this.$route.params.taskResultId).then(
        response => {
          this.taskData = response.data.data.result
          this.questions = response.data.data.answers.map(question => ({
            answer_id: question.task_question_essay_answer.id ?? '',
            number: question.number,
            question: question.question,
            answer_text:
                question.task_question_essay_answer.text || 'Tidak dijawab',
            answer_file: null,
            point: question.task_question_essay_answer.point ?? 0,
          }))
          this.isLoading = false
        },
      ).catch(() => {
        this.isLoading = false
      })
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    async submitForm() {
      const corrections = []
      this.questions.forEach(element => {
        corrections.push({
          answer_id: element.answer_id,
          point: element.point ?? 0,
        })
      })

      this.componentLoading = true
      await reqSaveEssayQuestionCorrection(this.$route.params.taskResultId, { corrections })
        .then(response => {
          this.showToast(
            'success',
            'Check',
            'Berhasil',
            'Berhasil Mengoreksi Tugas',
          )
          this.componentLoading = false
        })
        .catch(error => {
          this.showToast(
            'danger',
            'Check',
            'Gagal',
            error.response.data.message || 'Gagal Mengoreksi Tugas',
          )
          this.componentLoading = false
          this.errors = error.response.data.errors
          console.log(error)
        })
    },
  },
}
</script>

  <style lang="scss" scoped>
.extra-small-gap-bottom {
  margin-bottom: 4px;
}

.small-gap-left {
  margin-left: 8px;
}
.small-gap-right {
  margin-right: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.small-gap-top {
  margin-top: 8px;
}

.round-button {
  padding: 0.2%;
  margin: 0.7%;
  font-size: 0.7rem;
  height: 2.5rem;
  width: 2.5rem;
}

.w-30 {
  width: 30%;
}

.exam-answer-options .form-control[readonly] {
  cursor: pointer;
  background-color: white !important;
}

.exam-answer-options .delete-button {
  height: 2.714rem;
}
</style>
